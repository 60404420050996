#login-start {
  width: 45%;
  background-color: #4c4184;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#login-end {
  /* order: 1; */
  background: #000;
  width: 55%;
  /* width: 400px; */
  float: none !important;
  padding: 0;
}

@media (max-width: 992px) {
  .App-header {
    flex-direction: column;
  }

  #login-start {
    width: 100%;
  }

  #login-end {
    order: 1;
  }
}

.owl-stage {
  display: flex;
}

.owl-item {
  margin: 0;
  padding: 0;
}
