
.container-inner{
    background-color: #ECF0F5;
}


.Breadcrumb-inner{
  font-size: 1.1rem;
  font-weight: 700;
}

.Breadcrumb-inner a{
  color: #000;
}
