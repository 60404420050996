#NavTop-legal{
  background-color: #4c4184;
  height: 16rem;
}

#NavTop-legal ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

#NavTop-legal ul a{
  color: #fff;
  text-decoration: none;
  font-size: .9rem;
  opacity: .7;
  margin-left: 1rem;
}

#NavTop-legal ul a.active,
#NavTop-legal ul a:hover{
  opacity: 1;
}

#Main-legal{
  position: relative;
  top: -4rem;
}


.Main-legal-content{
  background-color: #fff;
  padding: 2rem;
  /* padding-top: .5rem; */
  border-radius: 1rem 1rem 0 0;
}

.bg-opacity-primary{
  background: #ecf0f5;
}

.Main-legal-content h4{
  position: absolute;
  top: -3.5rem; 
  color: #fff;
  font-size: 1.7rem;
  font-weight: 700;
  left: 0;
    right: 0;
}

.Main-legal-content a{
  color: #4c4184;
}


@media (max-width: 992px) {
  #Main-legal{
    top: -2rem;
  }

  #NavTop-legal{
    height: 18rem;
  }

  #NavTop-legal ul a{
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .Main-legal-content{
    padding: 1rem;
  }
}
