#login-end {
  flex: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#login-end .container-image {
  flex: 1;
  transition: 0.3s ease all;
  /* height: 16.66667%; */
  overflow: hidden;
  position: relative;
  display: flex;
  /* background-image: linear-gradient(45deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 70%)); */
  background-image: linear-gradient(
    45deg,
    rgb(0 0 0 / 80%),
    rgb(0 0 0 / 20%),
    rgb(0 0 0 / 40%)
  );
  align-items: flex-end;
  /* justify-content: flex-end;
  text-align: right; */
  height: 100vh;
}

#login-end .container-image:hover {
  flex: 5;
  /* background-color: rgb(0 0 0 / 30%); */
}

#login-end .container-image-text {
  position: relative;
  z-index: 11;
  color: #fff;
  padding: 2rem;
  margin-bottom: 2rem;
}

#login-end .container-image-text h4 {
  font-size: 1.1rem;
}

#login-end .container-image-text p {
  font-size: 0.9rem;
  margin: 0;
}

#login-end .container-image:nth-child(odd) {
  /* background-color: red; */
}
#login-end .container-image img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  /* display: none; */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.owl-dots {
  position: absolute;
  left: 0;
  bottom: .7rem;
  right: 0;
}

.owl-dots .owl-dot span{
  background: rgb(255 255 255 / 43%) !important;
}

.owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span{
  background-color: #fff !important;
}

@media (max-width: 992px) {

  #login-end{
    width: 100% !important;
    min-height: auto;
  }

  #login-end .container-image{
    flex-basis: 100%;
    height: 40vh;
  }
}