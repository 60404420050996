.NavStartInner{
    width: 240px;
    background-color: #ECF0F5;
    height: 100vh;
    padding-top: 3.5rem;
    transition: .3s all ease;

}

#logo-inner{
    max-width: 180px;
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* filter: brightness(0) invert(1); */
    width: 164px;
}

.NavStartInner .nav-link{
    border-left: 5px solid transparent;
    font-weight: 500;
    color: #B7B7B7;
    font-size: 1.1rem;
    padding-left: 1.5rem;
    color: #3c3c3c;
    padding-top: .7rem;
    padding-bottom: .7rem;
    border-radius: 0 .25rem .25rem 0;
}

.NavStartInner .nav-link:hover{
    color: inherit;
    background-color: #e5e8ed;

}

.NavStartInner .nav-link.active{
    border-left: 5px solid #4C4184;
    color: #4C4184;
    border-left: 5px solid #4C4184;
    color: #ffffff;
    /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
    background-color: #4C4184;
    color: #4C4184;
    background-color: #fff;
    color: #4c4184;
    background-color: #4c41841c;
}

.NavStartInner footer ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.NavStartInner footer ul a{
    color: #000;
    text-decoration: none;
}

.NavStartInner small{
    font-size: .7rem;
}

.NavStartInner h4{
    font-weight: bolder;
    /* padding-left: 1.5rem; */
    text-align: center;
}

@media (max-width: 768px) {

    .NavStartInner{
        position: absolute;
    padding-top: 2.5rem;
    left: -100%;
        z-index: 11;
        box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
    }

}