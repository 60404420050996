

.title-ccetic {
  font-weight: bolder;
  color: #fff;
  margin-bottom: 1.5rem;
}

.footer-login {
  /* margin-top: 2rem;   */
  /* position: absolute; */
  margin-bottom: 1.5rem;
}

.footer-login ul {
  list-style: none;
  display: flex;
}

.footer-login ul a {
  color: #fff;
  margin: 0 0.5rem;
  font-size: 0.8rem;
  opacity: 0.7;
  text-decoration: none;
  transition: 0.2s all ease;
}

.footer-login ul a:hover {
  opacity: 1;
  /* text-decoration: underline; */
}

#logo {
  max-width: 180px;
  display: block;
  /* margin: 0 auto; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  filter: brightness(0) invert(1);
  width: 164px;
  /* position: absolute; */
  /* top: -14rem; */
}

#btn-registrate{
  color: #FFF;
  opacity: 0.7;
  transition: 0.2s all ease;
  text-decoration: none;
}

#btn-registrate:hover{
  opacity: 1;
}

#passwordHelp{
  color: #4C4184;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: .875em;
}