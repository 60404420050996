.NavtopInner{
    background-color: #ECF0F5;
    position: fixed;
    z-index: 1;
    width: 100%;
    background: #4c4184;
    color: #fff;
    padding-top: .4rem;
    padding-bottom: .4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    z-index: 5;
}

.NavtopInner button{
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 0 !important;
    border: 0 !important;
}