/* Overwrite bootstrap */

.btn-primary {
  background-color: #4c4184;
  border-color: #4c4184;
}

.btn-primary:focus{
  background-color: #4c4184;
  border-color: #4c4184;
}

.btn-primary.disabled, .btn-primary:disabled{
  background-color: #4c4184;
  border-color: #4c4184;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled{
  color: #4c4184;
}


.btn:focus{
  box-shadow: none !important;
  outline: 0;
}

.btn-primary:hover {
  background-color: #5f539b;
  border-color: #5f539b;
}

.btn-info{
  background-color: #3a78b9 !important;
  border-color: #3a78b9 !important;
  color: #FFF !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #ca515d !important;
  border-color: #ca515d !important;
}

.shadow-lg {
  /* box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important; */
  /* box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2) !important; */
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
}

.page-link {
  color: #000;
}

.page-item.active .page-link {
  background-color: #4c4184;
  border-color: #4c4184;
}

.btn-outline-primary {
  color: #4c4184;
  border-color: #4c4184;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #4c4184;
  border-color: #4c4184;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #4c4184;
  outline: 0;
  box-shadow: none;
}

.form-select:focus {
  color: #212529;
  background-color: #fff;
  border-color: #4c4184;
  outline: 0;
  box-shadow: none;
}

.table-hover>tbody>tr:hover{
  --bs-table-accent-bg: rgb(0 0 0 / 4%);
}

.table thead tr{
  background-color: #f2f2f2;
}

/* Animations */

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4rem, 0);
    transform: translate3d(0, 4rem, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4rem, 0);
    transform: translate3d(0, 4rem, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* Pages */

.Side-end {
  width: calc(100% - 240px);
  float: right;
  padding-top: 3.5rem;
  background-color: #ecf0f5;
  /* top: 3rem; */
  /* position: relative; */
}

.Column-small {
  border-radius: .5rem;
  background-color: #fff;
  padding: 1.2rem;
  height: auto;
  align-self: self-start;
  top: 5rem;
}

.MainInner{
  border-radius: .5rem .5rem 0 0;
  background-color: #FFF;
  padding: 1.2rem !important;
}

.bg-opacity-primary{
  background: #ecf0f5;
}

@media (max-width: 1200px) {

  .Column-small{
    width: 100% !important;
    position: static !important;
    z-index: 1;
    margin-bottom: 1.5rem;
  }

}

@media (max-width: 768px) {

  .Side-end{
    width: 100% !important;
  }

  .Column-small{
    padding: 1rem;
  }

  .MainInner{
    padding: 1rem !important;
  }

}

#icon-bars{
  cursor: pointer;
}

.toggle-menu{
  left: 0;
}

#fade-menu{
  width: 0%;
  position: fixed;
  min-height: 100vh;
  background-color: transparent;
  z-index: 7;
  transition: .3s background ease;
}

.toggle-fade-menu{
  width: 100% !important;
  background-color: #0006 !important;
}

.table-fixed-end > tbody > tr > td:last-child,
.table-fixed-end > thead > tr > th:last-child
{
  position: sticky;
  background: #f5f5f5;
  right: 0;
  white-space: nowrap;
  box-shadow: inset 1px 0 #ddd;
}

.table-fixed-end > thead > tr > th:last-child{
  background: #f5f5f5;
  box-shadow: inset 1px 0 #ddd;

}

.table-orderby-active{
  color: #3a78b9;
  font-weight: 900;
}

.form-select:disabled {
  /* background-color: #efefef; */
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}